<template>
  <div id="game_content" />
  <!-- <div class="placeholder" v-else>
    Downloading ...
  </div> -->
</template>

<script>
export default {
  data() {
    return {
      downloaded: false,
      gameInstance: null,
      //containerId: 'game-container'
    }
  },
  async mounted() {
    const game = await import('@/game/game')
    this.downloaded = true
    this.$nextTick(() => {
      this.gameInstance = game.launch()
    })
  },
  unmounted() {
    this.gameInstance.destroy(false)
  }
}
</script>

<style type="text/css">
body{
  background-image: url('../game/img/cover.png');
  /* background-size: contain; */
  background-color: #000000;
}
.placeholder {
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
}
@font-face {
		  font-family: 'vanilla';
		  font-style: normal;
		  font-weight: 400;
		  src: url(../game/font/vanilla-extract.ttf) format('truetype');
		}

</style>
